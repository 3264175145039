@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}

#__next {
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  overscroll-behavior: contain;
}


/* Parallax Styles */
.parallax-container {
  --parallaxPerspective: 1;
  --zDistance: 0;
  --cardHeight: 60vh;

  /*margin: 5px;*/
  /*border: 1px solid red;*/
  /*width: calc(100% - 10px);*/
  /*height: calc(100% - 10px);*/

  width: 100%;
  height: 100%;

  overscroll-behavior: contain;

  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  perspective-origin: left top;
}

.parallax-card {
  position: absolute;
  top: calc(var(--cardHeight) / 2 * var(--zDistance));
  left: 0;
  width: 100vw;
  height: var(--cardHeight);
  --translateZ: calc(var(--zDistance) * -1px);
  --scale: calc((var(--parallaxPerspective) + var(--zDistance)) / var(--parallaxPerspective));
  transform: translateZ(var(--translateZ)) scale(var(--scale));
  transform-origin: 0;
}
